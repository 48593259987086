import Http from "../config/network/http";
import { APIExperience, APIForms } from "../config/constants";


const Services = {
    // GET
    getCoursesList: async () => await Http.GET(`${APIExperience}/api/courses/v1/courses/`, true), // for retrive all courses
    getCourseEnrollmentList: async () => await Http.GET(`${APIExperience}/api/enrollment/v1/enrollment`),
    getCoursePrice: async (COURSE_KEY) => await Http.GET(`${APIExperience}/api/enrollment/v1/course/${COURSE_KEY}`),
    getCourseDetails: async (COURSE_KEY) => await Http.GET(`${APIExperience}/api/courses/v1/courses/${COURSE_KEY}`),
    getCourseOutline: async (COURSE_KEY) => await Http.GET(`${APIExperience}/api/course_home/v1/outline/${COURSE_KEY}`),
    getCourseReview: async (COURSE_KEY) => await Http.GET(`${APIForms}/v1/reviews/courses/${COURSE_KEY}/`, true),
    getCourseProgress: async (COURSE_KEY) => await Http.GET(`${APIExperience}/api/course_home/v1/progress/${COURSE_KEY}`),
    getContentBlocks: async (CONTENT_KEY, params) => await Http.GET(`${APIExperience}/api/courses/v1/blocks/${CONTENT_KEY}/?username=${params.userName}&depth=all&requested_fields=graded,format,lti_url,due&block_counts=video&student_view_data=video&block_types_filter=html,problem,video,discussion`),
    downloadCertificate: async (COURSE_KEY, params) => await Http.GET(`${APIExperience}/api/certificates/v0/certificates/${params.userName}/courses/${COURSE_KEY}/`),
    // POST
    courseEnrollment: async (data) => await Http.POST(`${APIExperience}/api/enrollment/v1/enrollment`, data),
    addCourseReview: async (data) => await Http.POST(`${APIForms}/v1/reviews`, data),
    changeClientLanguage: async (lang, csrfToken) => {
        //
        var myHeaders = new Headers();
        myHeaders.append("x-csrftoken", csrfToken);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Request-Method", "*");
        var formdata = new FormData();
        formdata.append("language", lang);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };
        return await fetch(`${APIExperience}/i18n/setlang/`, requestOptions);
    },
    getUserPreferredLanguage: async (username) => await Http.GET(`${APIExperience}/api/user/v1/preferences/${username}`),
    handelChangeLanguage: async (lang, username, token) => {
        let data = {
            language_proficiencies: [
                {
                    code: lang
                }
            ]
        };
        await fetch(`${APIExperience}/api/user/v1/accounts/${username}`, {
            method: "PATCH",
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/merge-patch+json",
                Authorization: `Bearer ${token}`
            }
        }).catch((err) => console.log("err", err));
    },

    // PATCH
    changeUserPrefLanguage: async (username, lang) => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${JSON.parse(localStorage.getItem("user_stu") || "{}").access_token}`);
        myHeaders.append("Content-Type", "application/merge-patch+json");

        var raw = JSON.stringify({
            "pref-lang": lang
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${APIExperience}/api/user/v1/preferences/${username}`, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }
};

export default Services;
