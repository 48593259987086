import axios from "axios";
import moment from "moment";
import { APIExperience, ECOMMERCE_URL } from "../constants";
import Http from "../network/http";

const formData = new FormData();

// function handleClicklanguage(username) {
//   var axios = require("axios");
//   var data = JSON.stringify({
//     "pref-lang": "ar",
//   });

//   var config = {
//     method: "patch",
//     withCredentials: true,
//     url: `${APIExperience}/accounts/user_setlang/${username}`,
//     headers: {
//       "Accept-Language": "en-US,en;q=0.9,ar;q=0.8",
//       Connection: "keep-alive",
//       "Content-Type": "application/json",
//       "User-Agent":
//         "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36",
//     },
//     data: data,
//   };

//   axios(config)
//     .then((res) => {
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
// }
const Services = {
  ////////
  login: async (credentials) => {
    try {
      const res = await Http.POST(`${APIExperience}/oauth2/access_token/`, credentials, true);
      const username = await Http.GET(`${APIExperience}/api/user/v1/me`, {
        headers: { Authorization: `bearer ${res?.data?.access_token}` },
      }).then((res) => res.data.username);
      localStorage.setItem("user_stu", JSON.stringify({ ...res.data, username: username, expiration: moment().add(res?.data?.expires_in, "seconds").unix() }));
      formData.set("username", credentials.get("username"));
      formData.set("password", credentials.get("password"));
      const login_status = await Http.POST(`${APIExperience}/oauth2/login/`, formData, {
        withCredentials: true,
        headers: { Authorization: `bearer ${res?.data?.access_token}` }
      }).then((res) => res)
        .catch((err) => {
          return err;
        });;

      if (login_status?.response?.status === 401)
        throw new Error("Please Confirm your email and try again");

      return res.data

    } catch (error) {
      return error.res

    }
    // return await Http.POST(`${APIExperience}/oauth2/login/`, formData, {
    //   withCredentials: true,
    //   headers: { Authorization: `bearer ${res?.data?.access_token}` }
    // });
  },
  ////////
  // login: async (credentials) =>
  //   await Http.POST(`${APIExperience}/oauth2/access_token/`, credentials, true)
  //     .then(async (res) => {
  //       const login_status = await Http.POST(
  //         `${APIExperience}/oauth2/login/`,
  //         credentials,
  //         {
  //           withCredentials: true,
  //           headers: { Authorization: `bearer ${res?.data?.access_token}` },
  //         }
  //       )
  //         .then((res) => res)
  //         .catch((err) => {
  //           return err;
  //         });
  //       if (login_status?.response?.status === 401)
  //         throw new Error("Please Confirm your email and try again");
  //       const username = await axios({
  //         url: `${APIExperience}/api/user/v1/me`,
  //         headers: { Authorization: `bearer ${res?.data?.access_token}` },
  //       }).then((res) => res.data.username);
  //       localStorage.setItem(
  //         "user_stu",
  //         JSON.stringify({
  //           ...res.data,
  //           username,
  //           expiration: moment().add(32400, "seconds").unix(),
  //         })
  //       );
  //       handleClicklanguage(username);
  //       return res.data;
  //     })
  //     .catch((err) => err.response),

  publicLogin: async (credentials) => {
    return await Http.POST(
      `${APIExperience}/oauth2/access_token/`,
      credentials,
      true
    ).then((res) => {
      localStorage.setItem("userPublic_stu", JSON.stringify(res.data));
      return res.data;
    });
  },
  register: async (credentials) =>
    await Http.POST(`${APIExperience}/api/user/v1/account/registration/`, credentials)
      .then((res) => res.data)
      .catch((err) => err.response),

  logout: async () => {
    try {
      await axios({
        method: "POST",
        url: `${APIExperience}/logout`,
        withCredentials: true,
      });
    } catch { }
    window.location.href = `${ECOMMERCE_URL}/logout`;
  },
};

export default Services;
