import React from 'react'
import logo from "../../assets/learning_blue.webp"
import styles from "./LoadingImg.module.css"


function LoadingImg() {
    return (
        <div className={styles.loadingLogo} style={{ buffer: false }}>
            <img
                alt='loading'
                loading='eager'
                fetchpriority="high"
                width="50%"
                className={styles['loading-img']}
                src={logo} />
        </div>
    )
}

export default LoadingImg