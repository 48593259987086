export const HOST = window.location.origin;
export const WEBSITE_URL = "https://egstg.eyouthlearning.com";
export const APIExperience = "https://stg.eyouthlearning.com";
export const STUDIO_BASE_URL = "https://studio.stg.eyouthlearning.com";
export const ECOMMERCE_URL = "https://ecommerce.stg.eyouthlearning.com";
export const DISCOVERY_URL = "https://discovery.stg.eyouthlearning.com";
export const APIForms = "https://forms.eyouthlearning.com";
export const PROFILE_IMAGES_PREFIX = "https://files.stg.eyouthlearning.com/openedx/openedx"
export const CATEGORIES_LIST = [
    "Marketing",
    "Banking",
    "Languages",
    "Freelancing",
    "Sales",
    "Soft_Skills",
    "Programming_and_Technology",
    "Business_and_Management",

    "Education",
    "Career_Development",
    "Designing",
    "Entrepreneurship",
    "HR",
    "Operations",
    "Training_and_Development",
    "Tourism",

    "Technology_Science",
    "Oil_and_Gas",
    "PR",
    "Football_Commentaries",
    "Science",
    "Humanitarian_Work",
    "Teaching_and_Academics",
    "IT_and_Software",
    "Pharmacy",

]
export const NBD_Courses = ["course-v1:Banking+FISZZYH2023+FIUKAEX2023", "course-v1:Banking+CPV8LII2023+CPXMGS42023", "course-v1:Banking+SSKLMC62023+SSWF5NI2023"]

const CONSTANTS = {
    HOST,
    STUDIO_BASE_URL,
    APIExperience,
    APIForms,
    PROFILE_IMAGES_PREFIX,
    CATEGORIES_LIST, NBD_Courses
}

export default CONSTANTS