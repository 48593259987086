import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { Buffer } from 'buffer';
import React, { Suspense, useEffect, useState } from "react";
// import TagManager from 'react-gtm-module';
import "./i18n";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import './App.css';
import { LTRTheme, RTLTheme, cacheLTR, cacheRTL } from "./assets/styles/theme";
import LoadingImg from './components/Loading-Img/LoadingImg';
import Navbar from './components/navbar/navbar';
import { APIExperience, STUDIO_BASE_URL } from "./config/constants";
import { login, publicLogin } from "./config/state/actions";
import Partners from "./pages/Partners/Partners";
import Services from './servises/services';
// import axios from 'axios';

// import SDAProgram from './pages/SDAProgram/SDAProgram';

const Footer = React.lazy(() => import('./components/footer/footer'));
const CourseDetails = React.lazy(() => import('./pages/courseDetails/CourseDetails'));
const Home = React.lazy(() => import('./pages/home/home'));
const CourseReviews = React.lazy(() => import('./pages/coursereviwes/coursereviwes'));
const Studio = React.lazy(() => import('./pages/Studio/Studio'));
const Signup = React.lazy(() => import('./pages/signup/signup'));
const Signin = React.lazy(() => import('./pages/signin/signin'));
const Thankyou = React.lazy(() => import('./pages/thankyou/thankyou'));
const Mycourses = React.lazy(() => import('./pages/myCourses/mycourses'));
const Instructor = React.lazy(() => import("./pages/instructor/Instructor"));
const Instructors = React.lazy(() => import('./pages/instructors/Instructors'));
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop/ScrollToTop'));
const TermsAndCond = React.lazy(() => import('./pages/TermsAndCond/TermsAndCond'));
const PolicyAndPrivecy = React.lazy(() => import('./pages/PolicyAndPrivecy/PolicyAndPrivecy'));
const AddReviews = React.lazy(() => import('./pages/AddReview/AddReview'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const AllCoursesAndFilteration = React.lazy(() => import('./components/AllCoursesAndFilteration/AllCoursesAndFilteration'));
const Loading = React.lazy(() => import('./pages/loading/loading'));
const NotFoundPage = React.lazy(() => import('./pages/Not-Found/Not-Found'));
const CheckoutPreview = React.lazy(() => import("./pages/CheckoutPreview/CheckoutPreview"));
const ProgramsLandingPage = React.lazy(() => import("./pages/ProgramsLandingPage/ProgramsLandingPage"));
const SingleProgram = React.lazy(() => import("./pages/SingleProgram/SingleProgram"));
const ContactUs = React.lazy(() => import("./components/contactUs/contactUs"));
const DynaminPage = React.lazy(() => import("./pages/DynaminPage/DynaminPage"));
const EYouthProjectsPage = React.lazy(() => import("./pages/EYouthProjectsPage/EYouthProjectsPage"));
const ClimateChangeProgram = React.lazy(() => import("./pages/ClimateChangeProgram/ClimateChangeProgram"));
const SustainabilityCorporateProgram = React.lazy(() => import("./pages/SustainabilityCorporateProgram/SustainabilityCorporateProgram"));
const UserInfoForm = React.lazy(() => import("./components/UserInfoForm/UserInfoForm"));
const FAQ = React.lazy(() => import("./pages/FAQ's/FAQ's"));
const PlatformPolicy = React.lazy(() => import("./pages/PlatformPolicy/PlatformPolicy"));



// const SDAProgram = React.lazy(() => import("./pages/SDAProgram/SDAProgram"));

const AdminEnrollment = React.lazy(() => import("./pages/AdminEnrollment/AdminEnrollment"));



function App() {
  // const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
  const location = useLocation()

  // const navigate = useNavigate()
  const [URLSearchParams] = useSearchParams()
  const [token, setToken] = useState()
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  // //Country Location Detector 
  // const [country, setCountry] = useState(null);
  // const [ip, setIP] = useState("");
  // const API_KEY = "1805c853d4f34acdbe443592e4069dbc";
  // // const API_KEY = `${process.env.IPGeolocation_API}`;

  // useEffect(() => {
  //   // Get the user's IP address
  //   const getData = async () => {
  //     const res = await axios.get("https://api.ipify.org/?format=json");
  //     setIP(res.data.ip);
  //   };

  //   // Get the country from the user's IP address
  //   const getCountryFromIP = async (ip) => {
  //     try {
  //       const response = await axios.get(
  //         `https://api.ipgeolocation.io/ipgeo?apiKey=${API_KEY}&ip=${ip}`
  //       );
  //       const country = response.data.country_name;
  //       console.log(country)
  //       return country;
  //     } catch (error) {
  //       console.error("Error fetching country:", error);
  //       throw error; // Rethrow the error or handle it accordingly
  //     }
  //   };

  //   // Get the country and set it in the state
  //   const getCountry = async () => {
  //     const country = await getCountryFromIP(ip);
  //     setCountry(country);
  //   };

  //   // Get the country when the component mounts
  //   getCountry();

  //   // Get the country again whenever the IP address changes
  // }, [ip]);





  // const userLogOut = () => {
  //   navigate('/logout')
  //   localStorage.removeItem("user_stu")
  // }

  // useEffect(() => {
  //   if (session.expiration && session.expiration < Math.floor(new Date().getTime() / 1000)) {
  //     userLogOut()
  //   }
  //   else if (session.expiration_time && session.expiration_time < new Date().getTime()) {
  //     userLogOut()
  //   }
  //   // eslint-disable-next-line
  // }, [new Date().getTime()])

  useEffect(() => {
    const formData = new FormData();
    formData.append("grant_type", "client_credentials");
    formData.append("client_id", "FSv1Uco90EiAALFNCRQ0y2yqIvPcGgrtxcWaQh7b");
    formData.append("client_secret", "ErUer0eacFfFBOeUOvWxsE4YaVq0PjqayIoQZfARc1dY0oMBhCt30XTAF3ONu1CWAcPDS1MoH1LD0TTYLz81Qt0p35bCUCSM9HMK4xHZD98MPd4j65Wi1r6O05XPmFgA");
    formData.append("token_type", 'jwt');
    dispatch(publicLogin(formData))
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   const tagManagerArgs = {
  //     gtmId: 'GTM-PG4JD4HM'
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }, [location])

  useEffect(() => {
    if (token?.length > 10)
      localStorage.setItem("user_stu", token);
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (location.pathname.toLocaleLowerCase().includes("Studio")) {
      window.location.href = `${APIExperience}/login?next=/oauth2/authorize%3Fclient_id%3Dcms-sso%26redirect_uri%3Dhttps%253A%252F%252F${STUDIO_BASE_URL.slice(8, STUDIO_BASE_URL.length)}%252Fcomplete%252Fedx-oauth2%252F%253Fredirect_state%253Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26state%3Ds7ixquGOYIPbfOci4Uao1Oos0hGUGLKC%26response_type%3Dcode%26scope%3Duser_id%2Bprofile%2Bemail`
    }
    setToken(Buffer.from(decodeURI(URLSearchParams.get("token")), "base64").toString("utf-8"))
    // eslint-disable-next-line
  }, [location, URLSearchParams])

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("user_stu") || "{}");
    if (session.expiration_time && session.expiration_time < new Date().getTime()) {
      const formData = new FormData();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", "login-service-client-id");
      formData.append("refresh_token", session.refresh_token);
      dispatch(login(formData))
    }
    if (Object.keys(session).length > 0) {
      Services.getUserPreferredLanguage(session?.username).then((res) => {
        // let lang = res.data["pref-lang"];
        // i18n.changeLanguage(lang);
        i18n.changeLanguage("ar");
        // document.documentElement.lang = `${i18n.language === "en" ? "en" : "ar"}`;
        // document.dir = i18n.language === "en" ? "ltr" : "rtl";
        document.documentElement.lang = "ar";
        document.dir = i18n.language === "rtl";
      });
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // setLanguage(i18n.language);
    // document.documentElement.lang = `${i18n.language === "en" ? "en" : "ar"}`;
    // document.dir = i18n.language === "en" ? "ltr" : "rtl";
    setLanguage("ar");
    document.documentElement.lang = "ar";
    document.dir = i18n.language === "rtl";
  }, [i18n.language]);

  return (
    <CacheProvider value={language === "ar" ? cacheRTL : cacheLTR}>
      <ThemeProvider theme={language === "ar" ? RTLTheme : LTRTheme}>
        <div className="container-fluid m-0 p-0">
          {location.pathname !== '/loading' && <Navbar token={token} />}
          <ScrollToTop />
          <Suspense fallback={<LoadingImg />}>
            <div style={{ minHeight: "50vh" }}>
              <Routes>
                <Route exact path="/" element={<Home />}> </Route>
                <Route path="/signup" element={<Signup />}></Route>
                <Route path="/signin" element={<Signin />}></Route>
                <Route path="/all-courses" element={<AllCoursesAndFilteration />}> </Route>
                <Route path="/thankyou" element={<Thankyou />}> </Route>
                <Route path="/Mycourses" element={<Mycourses />}> </Route>
                <Route path="/instructors" element={<Instructors />}> </Route>
                <Route path="/course-reviews/:id" element={<CourseReviews />}> </Route>
                <Route path="/instructor/:id" element={<Instructor />}> </Route>
                <Route path="/add-review/:id" element={<AddReviews />}> </Route>
                <Route path="/studio" element={<Studio />}> </Route>
                <Route path="/details/:id" element={<CourseDetails />}> </Route>
                <Route path="/terms-conditions" element={<TermsAndCond />}></Route>
                <Route path="/Policy" element={<PolicyAndPrivecy />}></Route>
                <Route path="/logout" element={<Logout />}></Route>
                <Route path="/loading" element={<Loading />}></Route>
                <Route path="/partners" element={<Partners />}></Route>
                <Route path="/checkout-preview/" element={<CheckoutPreview />}></Route>
                <Route path="/programs/" element={<ProgramsLandingPage />}></Route>
                <Route path="/program/:id" element={<SingleProgram />}></Route>
                <Route path="/contact-us" element={<ContactUs pageName="contact_us" />}></Route>
                <Route path="/quick-learnings" element={<DynaminPage />}></Route>
                <Route path="/wfp" element={<DynaminPage />}></Route>
                {/* <Route path="/nbd" element={<DynaminPage />}></Route> */}
                <Route path="/our-projects" element={<EYouthProjectsPage />}></Route>
                <Route path="/scip" element={<SustainabilityCorporateProgram />}></Route>
                <Route path="/climate-program" element={<ClimateChangeProgram />}></Route>
                <Route path="/sdap" element={<ClimateChangeProgram />}></Route>
                <Route path="/enrollment" element={<AdminEnrollment />}></Route>
                <Route path="/user-form" element={<UserInfoForm />}></Route>
                <Route path="/faq" element={<FAQ />}></Route>
                <Route path="/platform-policy" element={<PlatformPolicy />}></Route>
                <Route path="*" element={<NotFoundPage />} /></Routes>
            </div>
          </Suspense>
          {location.pathname !== '/loading' && <Footer />}
        </div>
      </ThemeProvider>
    </CacheProvider>

  );
}

export default App;